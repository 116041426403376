<template>
  <b-overlay
      :show="is_loading"
      blur="50"
      rounded="lg"
      spinner-lg
      spinner-variant="primary"
      variant="white"
  >
    <transition mode="out-in" name="zoom-fade">
      <div v-if="confirm">
        <div class="d-flex mb-2">
          <img alt="" class="mx-auto" src="@/assets/images/site/icons/success.svg">
        </div>
        <b-row>
          <b-col lg="12" md="12">
            <q-header>
              <b>{{$t('modals.lending-callback.after.header-a')}}
                <br>
                {{$t('modals.lending-callback.after.header-b')}}</b>
            </q-header>
            <p class="dark animate__animated text-overlay" data-anime="animate__fadeIn" data-delay="200"
               style="text-align: center; margin: 15px 30px 30px 30px">
              {{$t('modals.lending-callback.after.text')}}
            </p>
            <q-btn id="return-btn" block class="mt-2" variant="secondary" @click="$bvModal.hide('consultation')">
              {{$t('modals.lending-callback.after.btn')}}
            </q-btn>
          </b-col>
        </b-row>

      </div>
      <div v-else>
        <div>
          <b-card class="mb-0 callback-form-nd">
            <!-- form -->
            <q-header :centered="false">
              <b>{{$t('modals.lending-callback.before.header-a')}}
                <span class="position-relative">
                <img alt=""
                     class="position-absolute"
                     src="@/@core/assets/images/btn_sweep.svg"
                     style="transform: rotate(135deg); right: -30px;">{{$t('modals.lending-callback.before.header-b')}}
                </span>
                <br>
                {{$t('modals.lending-callback.before.header-c')}}</b>
            </q-header>
            <validation-observer ref="callbackForm">
              <b-form
                  class="auth-register-form mt-2"
                  @submit.prevent="validationForm"
              >
                <!-- username -->
                <b-form-group :label="$t('modals.lending-callback.before.name')" label-for="username">
                  <validation-provider
                      #default="{ errors }"
                      name="Username"
                      rules="required"
                  >
                    <b-form-input
                        id="username"
                        v-model="username"
                        :state="errors.length > 0 ? false : null"
                        name="register-username"
                        :placeholder="$t('modals.lending-callback.placeholder')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- messangers -->
                <b-form-group
                    class="mt-2 mb-2"
                    :label="$t('modals.lending-callback.before.can-contact')"
                    label-for="type"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                  >
                    <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        class="input-group-merge messengers-select"
                    >
                      <div id="msg-call" :class="selectedMessanger.call && 'selected-messenger'"
                           class="mesanger-block messenger-select-button"
                           @click="selectMessanger('call', true)">
                        <div class="icon-box">
                          <svg fill="none" height="28" viewBox="0 0 28 28" width="28"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.0117 8.86996L10.2688 8.82947C10.0355 8.81674 9.80665 8.89471 9.6303 9.0479C9.27018 9.36062 8.69436 9.96521 8.5175 10.7531C8.25376 11.9278 8.66135 13.3662 9.71624 14.8047C10.7711 16.2432 12.737 18.5447 16.2133 19.5277C17.3335 19.8444 18.2147 19.6309 18.8946 19.196C19.4331 18.8515 19.8043 18.2987 19.938 17.6736L20.0566 17.1197C20.0943 16.9436 20.0049 16.765 19.8414 16.6896L17.3309 15.5324C17.1679 15.4573 16.9747 15.5048 16.865 15.6469L15.8795 16.9245C15.805 17.021 15.6776 17.0593 15.5625 17.0189C14.8876 16.7817 12.6268 15.8348 11.3862 13.4452C11.3324 13.3416 11.3458 13.2158 11.4221 13.1274L12.364 12.0377C12.4603 11.9265 12.4846 11.77 12.4267 11.6348L11.3446 9.10307C11.287 8.96828 11.1579 8.87795 11.0117 8.86996Z"
                                fill="#1A1C23"/>
                            <circle cx="14" cy="14" r="13.25" stroke="#1A1C23" stroke-width="1.5"/>
                            <path
                                d="M15.0107 8C14.8671 8 14.7172 8 14.5736 8C14.408 8.01407 14.2548 8.09335 14.1476 8.22039C14.0405 8.34743 13.9882 8.51183 14.0023 8.67742C14.0163 8.84301 14.0956 8.99622 14.2227 9.10336C14.3497 9.21049 14.5142 9.26277 14.6798 9.2487H15.0107C16.0044 9.2487 16.9574 9.64338 17.66 10.3459C18.3626 11.0484 18.7574 12.0013 18.7574 12.9948C18.7574 13.1072 18.7574 13.2133 18.7574 13.3257C18.7435 13.4904 18.7956 13.6539 18.9021 13.7803C19.0086 13.9067 19.1609 13.9857 19.3256 14H19.3756C19.5319 14.0006 19.6828 13.9426 19.7984 13.8374C19.914 13.7322 19.9859 13.5875 20 13.4318C20 13.2882 20 13.1384 20 12.9948C20 11.6712 19.4745 10.4017 18.5391 9.46515C17.6036 8.52862 16.3345 8.00165 15.0107 8ZM16.2596 12.9948C16.2596 13.1604 16.3254 13.3192 16.4425 13.4363C16.5596 13.5534 16.7184 13.6191 16.884 13.6191C17.0497 13.6191 17.2085 13.5534 17.3256 13.4363C17.4427 13.3192 17.5085 13.1604 17.5085 12.9948C17.5085 12.3324 17.2453 11.6972 16.7769 11.2289C16.3085 10.7605 15.6732 10.4974 15.0107 10.4974C14.8451 10.4974 14.6863 10.5632 14.5692 10.6803C14.4521 10.7974 14.3863 10.9562 14.3863 11.1217C14.3863 11.2873 14.4521 11.4461 14.5692 11.5632C14.6863 11.6803 14.8451 11.7461 15.0107 11.7461C15.3419 11.7461 15.6596 11.8777 15.8938 12.1118C16.128 12.346 16.2596 12.6636 16.2596 12.9948Z"
                                fill="#1A1C23"/>
                          </svg>
                        </div>

                        <span class="text-muted" style="font-size: 9px">{{$t('modals.lending-callback.before.call')}}</span>
                      </div>

                      <div id="msg-whatsapp" :class="selectedMessanger.whatsapp && 'selected-messenger'"
                           class="mesanger-block messenger-select-button"
                           @click="selectMessanger('whatsapp', true)">
                        <div class="icon-box">
                          <svg fill="none" height="24" viewBox="0 0 24 24" width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.75 23.6999L2.35554 17.9975C1.32308 16.2435 0.779488 14.2479 0.779488 12.1978C0.779488 5.88546 5.91495 0.75 12.2273 0.75C18.5396 0.75 23.675 5.88546 23.675 12.1978C23.675 18.5101 18.5396 23.6456 12.2273 23.6456C10.2604 23.6456 8.33444 23.1421 6.63065 22.1861L0.75 23.6999ZM6.93129 20.1034L7.2817 20.3174C8.7672 21.2243 10.4774 21.7037 12.2273 21.7037C17.4688 21.7037 21.7331 17.4393 21.7331 12.1978C21.7331 6.95625 17.4688 2.69193 12.2273 2.69193C6.98574 2.69193 2.72141 6.95625 2.72141 12.1978C2.72141 14.0241 3.24048 15.7977 4.22238 17.3267L4.45836 17.6942L3.53379 20.978L6.93129 20.1034Z"
                                fill="#1A1C23"/>
                            <path
                                d="M9.0115 6.86996L8.26868 6.82947C8.03536 6.81674 7.8065 6.89471 7.63014 7.0479C7.27002 7.36062 6.69421 7.96521 6.51735 8.75306C6.25361 9.92778 6.66119 11.3662 7.71609 12.8047C8.77099 14.2432 10.7369 16.5447 14.2131 17.5277C15.3333 17.8444 16.2145 17.6309 16.8944 17.196C17.4329 16.8515 17.8041 16.2987 17.9379 15.6736L18.0565 15.1197C18.0942 14.9436 18.0048 14.765 17.8412 14.6896L15.3307 13.5324C15.1678 13.4573 14.9745 13.5048 14.8649 13.6469L13.8793 14.9245C13.8049 15.021 13.6774 15.0593 13.5624 15.0189C12.8874 14.7817 10.6266 13.8348 9.38608 11.4452C9.33228 11.3416 9.34565 11.2158 9.42196 11.1274L10.3639 10.0377C10.4601 9.92648 10.4844 9.77005 10.4266 9.63483L9.34443 7.10307C9.28682 6.96828 9.15772 6.87795 9.0115 6.86996Z"
                                fill="#1A1C23"/>
                          </svg>
                        </div>

                        <span class="text-muted" style="font-size: 9px">whatsapp</span>
                      </div>

                      <div id="msg-viber" :class="selectedMessanger.viber && 'selected-messenger'"
                           class="mesanger-block messenger-select-button"
                           @click="selectMessanger('viber', true)">
                        <div class="icon-box">
                          <svg fill="none" height="22" viewBox="0 0 22 22" width="22"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M20.6036 5.23697L20.5973 5.21183C20.0889 3.15643 17.7967 0.950875 15.6917 0.492025L15.668 0.487136C12.2633 -0.162379 8.81105 -0.162379 5.40703 0.487136L5.38259 0.492025C3.2783 0.950875 0.986145 3.15643 0.477009 5.21183L0.471422 5.23697C-0.157141 8.10741 -0.157141 11.0183 0.471422 13.8888L0.477009 13.9139C0.964424 15.8816 3.08589 17.9858 5.11231 18.5645V20.8589C5.11231 21.6893 6.12429 22.0972 6.69978 21.4979L9.02441 19.0815C9.52859 19.1098 10.033 19.1255 10.5375 19.1255C12.2514 19.1255 13.966 18.9634 15.668 18.6387L15.6917 18.6338C17.7967 18.1749 20.0889 15.9694 20.5973 13.914L20.6036 13.8889C21.2321 11.0183 21.2321 8.10741 20.6036 5.23697ZM18.764 13.4725C18.4246 14.8135 16.6841 16.4806 15.3013 16.7885C13.491 17.1328 11.6663 17.2799 9.8435 17.2295C9.80725 17.2285 9.7724 17.2425 9.74712 17.2685C9.48843 17.534 8.04979 19.0109 8.04979 19.0109L6.24442 20.8637C6.11242 21.0013 5.88055 20.9077 5.88055 20.7178V16.9169C5.88055 16.8541 5.83571 16.8008 5.77404 16.7887C5.77369 16.7886 5.77335 16.7885 5.773 16.7885C4.39016 16.4805 2.65044 14.8134 2.31032 13.4725C1.74461 10.8779 1.74461 8.24771 2.31032 5.65315C2.65044 4.31222 4.39016 2.64513 5.773 2.33713C8.93466 1.73581 12.1403 1.73581 15.3013 2.33713C16.6848 2.64513 18.4246 4.31222 18.764 5.65315C19.3303 8.24778 19.3303 10.878 18.764 13.4725Z"
                                fill="#1A1C23"/>
                            <path
                                d="M13.5461 15.2279C13.3335 15.1634 13.1309 15.12 12.9428 15.0419C10.9931 14.233 9.19875 13.1895 7.77743 11.5898C6.96917 10.6801 6.33656 9.6531 5.80179 8.56624C5.5482 8.05082 5.33449 7.51522 5.11666 6.9831C4.91803 6.49785 5.21059 5.99654 5.51866 5.63093C5.80772 5.2878 6.17969 5.0252 6.58253 4.83167C6.89695 4.68061 7.20711 4.7677 7.43675 5.03421C7.93317 5.61039 8.38916 6.21605 8.75841 6.88393C8.98546 7.29473 8.92316 7.79688 8.51166 8.07645C8.41165 8.14441 8.32051 8.22417 8.22734 8.30092C8.14563 8.36818 8.06873 8.43613 8.01272 8.5272C7.91034 8.69384 7.90545 8.89037 7.97138 9.07154C8.47884 10.4661 9.33417 11.5505 10.7378 12.1346C10.9624 12.228 11.188 12.3369 11.4468 12.3067C11.8801 12.256 12.0204 11.7807 12.3241 11.5324C12.6209 11.2897 13.0002 11.2865 13.32 11.4888C13.6398 11.6912 13.9498 11.9085 14.2579 12.128C14.5604 12.3436 14.8615 12.5544 15.1406 12.8001C15.4088 13.0364 15.5012 13.3463 15.3501 13.667C15.0736 14.2542 14.6712 14.7427 14.0906 15.0546C13.9267 15.1425 13.731 15.171 13.5461 15.2279C13.3335 15.1633 13.731 15.171 13.5461 15.2279Z"
                                fill="#1A1C23"/>
                            <path
                                d="M10.5438 4.15926C13.094 4.23071 15.1886 5.92315 15.6374 8.44439C15.7139 8.87397 15.7411 9.3132 15.7752 9.74921C15.7895 9.93261 15.6856 10.1069 15.4877 10.1092C15.2833 10.1117 15.1913 9.94065 15.178 9.75732C15.1518 9.39443 15.1335 9.02993 15.0835 8.67004C14.8195 6.77031 13.3047 5.19863 11.4138 4.86137C11.1292 4.8106 10.838 4.79726 10.5498 4.76702C10.3676 4.74788 10.1289 4.73684 10.0885 4.51035C10.0547 4.32046 10.215 4.16925 10.3958 4.15954C10.4449 4.15682 10.4944 4.15905 10.5438 4.15926C13.094 4.23071 10.4944 4.15905 10.5438 4.15926Z"
                                fill="#1A1C23"/>
                            <path
                                d="M14.4187 9.18361C14.4145 9.21545 14.4123 9.29032 14.3936 9.36086C14.3259 9.6169 13.9378 9.64895 13.8484 9.39054C13.8219 9.31386 13.8179 9.22663 13.8178 9.14408C13.8169 8.60393 13.6995 8.06434 13.4271 7.59439C13.1471 7.11137 12.7193 6.70532 12.2176 6.45962C11.9142 6.31107 11.5861 6.21874 11.2536 6.16371C11.1083 6.13968 10.9614 6.12509 10.8154 6.10476C10.6384 6.08018 10.5439 5.96739 10.5523 5.79299C10.5601 5.62957 10.6795 5.51203 10.8576 5.52208C11.4429 5.55533 12.0082 5.68181 12.5285 5.95733C13.5865 6.51766 14.1909 7.40212 14.3674 8.58312C14.3753 8.63662 14.3882 8.68962 14.3922 8.74333C14.4022 8.87596 14.4085 9.00873 14.4187 9.18361C14.4145 9.21545 14.4085 9.00873 14.4187 9.18361Z"
                                fill="#1A1C23"/>
                            <path
                                d="M12.8328 9.12125C12.6194 9.12509 12.5052 9.00699 12.4832 8.81137C12.4679 8.67497 12.4559 8.53676 12.4233 8.40406C12.3592 8.14272 12.2203 7.90051 12.0004 7.74037C11.8966 7.66473 11.779 7.60963 11.6558 7.57408C11.4993 7.52889 11.3368 7.54133 11.1806 7.50305C11.0111 7.4615 10.9173 7.32419 10.9439 7.1651C10.9682 7.02032 11.1091 6.90731 11.2672 6.91877C12.256 6.99015 12.9626 7.50131 13.0635 8.66527C13.0706 8.7474 13.079 8.83421 13.0608 8.91278C13.0295 9.04729 12.9299 9.11469 12.8328 9.12125C12.6194 9.12509 12.9299 9.11469 12.8328 9.12125Z"
                                fill="#1A1C23"/>
                          </svg>
                        </div>

                        <span class="text-muted" style="font-size: 9px">viber</span>
                      </div>

                      <div id="msg-telegram" :class="selectedMessanger.telegram && 'selected-messenger'"
                           class="mesanger-block messenger-select-button"
                           @click="selectMessanger('telegram', true)">
                        <div class="icon-box">
                          <svg fill="none" height="17" viewBox="0 0 20 17" width="20"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.49361 7.44627L18.5209 0.881132C19.3112 0.595627 20.0014 1.07392 19.7453 2.26892L19.7468 2.26745L16.8476 15.9261C16.6327 16.8944 16.0573 17.1299 15.2523 16.6737L10.8373 13.4198L8.70776 15.4713C8.47229 15.7068 8.27362 15.9054 7.8174 15.9054L8.13087 11.4124L16.3134 4.02021C16.6695 3.70674 16.2339 3.53015 15.7644 3.84214L5.65257 10.2086L1.29346 8.84877C0.347178 8.54855 0.326575 7.90249 1.49361 7.44627Z"
                                fill="#1A1C23"/>
                          </svg>
                        </div>

                        <span class="text-muted" style="font-size: 9px">telegram</span>
                      </div>

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- phone number -->
                <b-form-group
                    v-show="!isCodeSend"
                    :label="$t('modals.lending-callback.before.phone')"
                    label-for="phone"
                    style="margin-bottom: 40px"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                  >
                    <b-input-group
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        class="input-group-merge phone-group"
                    >
                      <b-input-group-prepend :class="country ? 'cb-flags' : 'hidden'" is-text>
                        <b-link v-if="country" class="text-black r-border0"
                                style="font-size: 13px; display: flex; align-items: center;"
                                @click="showCountryModal = true">
                          <country-flag :country="country.iso" size='small'/>
                          <span>{{ country.code }}</span>
                        </b-link>
                      </b-input-group-prepend>
                      <b-form-input
                          id="phone"
                          v-model="regPhone"
                          v-mask="country ? country.mask : '+7 (###) ###-##-##'"
                          :class="country ? `l-border0` : `l-border100 p-f` "
                          :placeholder="
                          country ? country.mask : '+7 (###) ###-##-##'"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="register-phone"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <q-btn block type="submit" variant="primary">
                  {{$t('modals.lending-callback.before.get-advice')}}
                </q-btn>
              </b-form>
            </validation-observer>

            <b-card-text
                v-if="error"
                class="text-danger text-center mt-2"
                variant="danger"
            >
              {{ error }}
            </b-card-text
            >
          </b-card>
        </div>

      </div>
    </transition>


    <b-modal
        v-model="showCountryModal"
        centered
        modal-class="with-no-paddings"
        ok-only
        :ok-title="$t('modals.country.ok')"
        :title="$t('modals.country.title')"
    >

      <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
        <b-list-group-item v-for="c in countries" :key="c.iso" :active=" country ? (c.iso === country.iso) : false "
                           style="cursor: pointer;" @click="country = c; showCountryModal = false;">
          <div class="d-flex justify-content-start align-items-center">
            <div style="margin-right: 8px;">
              <!-- <b-img :src="c.flag" fluid /> -->
              <country-flag :country="c.iso" size='small'/>
            </div>
            <div class="flex-grow-1">
              {{ c.name }}
            </div>
            <div>
              {{ c.code }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

    </b-modal>

  </b-overlay>
</template>

<script>
import User from "@/modules/user/";
import {checked, confirmed, email, password, required} from "@validations";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import * as SuccessAnimeView from "@/assets/anime/success.json"

import {
  BButton,
  BCard,
  BCardText,
  BCardTitle,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
} from "bootstrap-vue";

import useAppConfig from "@core/app-config/useAppConfig";

export default {
  props: {
    selected: {
      type: String,
      default: 'call'
    }
  },
  data() {
    return {
      User,
      country: null,
      countries: require("countries-phone-masks"),
      showCountryModal: false,

      selectedMessanger: {
        whatsapp: false,
        telegram: false,
        viber: false,
        call: true,
      },

      regPhone: "",
      username: "",

      i: null,

      status: "",

      error: null,

      isCodeSend: false,

      skin: useAppConfig().skin,

      animationData: {
        animationData: SuccessAnimeView.default,
        loop: false
      },
      confirm: null,
      is_loading: false,

      // validation rules
      required,
      confirmed,
      password,
      email,
      checked,
    };
  },
  methods: {
    selectMessanger(messanger, event) {
      this.selectedMessanger = {
        whatsapp: false,
        telegram: false,
        viber: false,
        call: false,
      };

      if (!event) {
        this.selectedMessanger.call = true;
      } else this.selectedMessanger[messanger] = event;

    },

    closeModal() {
      this.$root.$emit("closeModal");
    },

    validationForm() {
      this.error = null;

      this.$refs.callbackForm.validate().then((success) => {
        if (success) {
          let messanger = "";
          for (let key in this.selectedMessanger) {
            if (this.selectedMessanger[key] === true) messanger = key;
          }


          this.$request
              .post("integrations/amocrm.createLead", {
                name: this.username,
                phone: this.country ? `${this.country.code}${this.regPhone}` : this.regPhone,
                messanger: messanger == 'call' ? 'звонок' : messanger,
                utm_tags: localStorage.getItem("utmTags") ? JSON.parse(localStorage.getItem("utmTags")) : null
              })
              .then((result) => {

                this.confirm = true;
                // localStorage.removeItem('utmTags');
                // setTimeout(() => {
                  // this.$router.push("register");
                // }, 2500);
              })
              .catch((err) => {
                this.error = err.message;
              });
          // this.closeModal();
        }
      });
    },
  },
  computed: {},
  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  watch: {},
  mounted() {
    this.selectMessanger(this.selected, true)

    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase();
    console.log('ISO код страны');
    console.log(userLocale);

    this.country = this.countries.find( country => country.iso === userLocale );

    if (this.country == undefined) {
      this.country = this.countries.find( country => country.iso === 'RU' );
    }
  },
};
</script>

<style lang="scss">
#return-btn {
  background-color: white !important;
}

#return-btn:not(button) {
  border: 1px solid var(--theme-accent-light);
  border-radius: 100px;
}

.callback-form-nd {
  .card-body {
    padding: 12px;

  }

  .messengers-select {
    cursor: pointer;

    .icon-box {
      display: flex;
      padding: 6px;
      border-radius: 100px;
      width: 40px;
      height: 40px;
      transition: all .3s ease;
      background: #F4F5F6;

      svg {
        transition: all .3s ease;
        margin: auto;
      }
    }

    .selected-messenger {
      svg {
        path {
          fill: white;
        }

        circle {
          stroke: white;
        }
      }
    }

    #msg-call.selected-messenger {
      .icon-box {
        background-color: var(--theme-accent-light);
      }
    }

    #msg-whatsapp.selected-messenger {
      .icon-box {
        background-color: #00d420;
      }
    }

    #msg-telegram.selected-messenger {
      .icon-box {
        background-color: #07c5ff;
      }
    }

    #msg-viber.selected-messenger {
      .icon-box {
        background-color: #b66cff;
      }
    }

    .text-muted {
      margin-top: 4px;
    }
  }

  .auth-register-form {
    input {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      border-radius: 0;
      font-size: 24px !important;
      padding-left: 0;
      font-weight: 500 !important;
      color: var(--text-primary-color);

      &:focus {
        box-shadow: none !important;
      }
    }

    label {
      font-size: 13px;
      color: var(--text-primary-color)
    }
  }

  .cb-flags .input-group-text a:focus {
    background: none!important;
  }

  .phone-group {
    * {
      border-radius: 0 !important;
    }

    .input-group-text {
      background-color: white !important;
      border: none
    }

    .cb-flags {
      margin-top: -4px;

      span {
        font-size: 24px !important;
        font-weight: 500 !important;
      }

      .small-flag {
        margin: -0.9em -0.7em -0.9em -1.2em;
      }

      &:focus, &:active, .input-group-text:focus {
        background-color: white !important;
      }
    }
  }

  .input-group:not(.bootstrap-touchspin):focus-within {
    box-shadow: none !important;
  }
}


</style>

<style>
#callback-modal .close {
  width: 40px;
  height: 26px;
}

#callback-modal {
  /* overflow: hidden; */
}

body.modal-open {
  overflow-y: hidden !important;
}

#callback-modal .card-body {
  /* padding: 0px 32px 0px 32px; */
  padding: 0px 0px 30px 0px;
}

#callback-modal .close {
  color: rgba(169, 169, 169, 1);
  background: rgba(169, 169, 169, 0.25);
  /* transform: translate(-30px, -5px) !important; */
  transform: translate(-33px, -2px) !important;
  font-size: 22px;
}

#callback-modal .modal-title {
  font-weight: 600;
}

.p-f {
  padding: 0.438rem 1rem !important;
}

#callback-modal input,
#callback-modal input:focus {
  border-radius: 100px;
  background-color: #f4f5f6;
  border-color: #f4f5f6;
}

#callback-modal .modal-body {
  padding: 8px 40px 0px 40px;
}

#callback-modal .modal-header {
  padding-left: 40px;
}

#callback-modal label {
  font-size: 16px;
  font-weight: 500;
  /* margin-bottom: 1.2857rem; */
  margin-bottom: 0.9rem;
  padding-top: 0.3rem;
}

#callback-modal .img-fluid {
  width: 35px;
  height: 35px;
  /* margin-right: 16px; */
}

#callback-modal .btn-block {
  border-radius: 100px;
  /* background-color: #426FF6 !important; */
  background-color: rgba(26, 86, 255, 1);
  color: #fff;
}

#callback-modal .input-group {
  border-radius: 100px;
}

.mesanger-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  padding: 0.438rem 0;
}

.btn-callback {
  margin-top: 35px;
}

.l-border0 {
  border-radius: 0px 100px 100px 0px !important;
}

.l-border100 {
  border-radius: 100px;
}

.cb-flags .input-group-text {
  border-radius: 100px 0px 0px 100px !important;
  background-color: rgb(244, 245, 246) !important;
  border-color: rgb(244, 245, 246) !important;
}

.cb-flags .input-group:not(.bootstrap-touchspin):focus-within .input-group-text, .cb-flags .input-group-text:focus, .cb-flags .input-group-text a:focus, .cb-flags .input-group-text span:focus {
  background-color: rgb(244, 245, 246) !important;
  border-color: rgb(244, 245, 246) !important;
}
</style>
